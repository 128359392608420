.menu-icon7 {
  height: var(--icon-size);
  width: var(--icon-size);
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.button80 {
  position: relative;
  
  /* line-height: 130%; */
  /* font-weight: 600; */
  font-size: var(--primary-font-size);
  font-family: var(--primary-font-family-bold);
  color: #fff;
  text-align: center;
}
.buttons-states-dark67 {
  cursor: pointer;
  border: 0;
  /* padding: 0 12px; */
  background-color: var(--hp-grey-500);
  flex: 1;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 0 20px;
  min-width: 169px;
  min-height: 50px;
  white-space: nowrap;
}
.buttons-states-dark67:hover {
  background-color: #5c5c5c;
}
.basket-icon8 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.button81 {
  position: relative;
  /* font-size: 18px;
  line-height: 130%; */
  font-size: var(--primary-font-size-mini);
  font-family: var(--primary-font-family-bold);
  color: #fff;
  text-align: center;
}
.buttons-states-dark68 {
  cursor: pointer;
  border: 0;
  padding: 0 12px;
  background-color: #c21f24;
  flex: 1;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 0 10px;
  min-width: 169px;
  min-height: 50px;
  white-space: nowrap;
  border: 1px solid #c21f24;
}
.buttons-states-dark68:hover {
  /* background-color: #e8454a; */
  border: 1px solid #ffffff;
  transition: border-color 0.3s ease;
}
.buttons-states-dark-parent6 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 20px;
  flex-shrink: 0;
}
