:root{
  scroll-behavior: smooth;
  --primary: #C21F24;
  --second:#E4B637;
  --bgColor:rgb(45,45,45);
  --primary-text-color:rgb(255,255,255);
  --primary-text-color-light:rgb(123,123,123);
  --card-dark-shade:rgb(50,50,50);
  --text:rgb(255,255,255);
  --droup-down-bg:rgb(37,37,37);
  --header-bg:rgb(0,0,0);
}

li .relative:hover span {
  transform: scaleX(1); /* Show the underline on hover */
}
.responsive-font-size{
  font-size: var(--primary-font-size);
  font-family: var(--primary-font-family);
  letter-spacing: 1px;
}
.links {
  list-style: none;
  margin: 0 20px 0 auto;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 20px;
}

.dropdown5 {
  position: relative;
  cursor: pointer;
}

.dropdown5 > a {
  display: flex;
  align-items: center;
  gap: 2px;
  height: 72px;
}

.dropdown5 > a > span {
  font-size: 20px;
  color: rgb(255 255 255 / 24%);
  translate: 0 2px;
}

.menu2 {
  position: absolute;
  top: 60px;
  right: -20px;
  /* width: 180px; */
  border-radius: 10px;
  border: 1px solid var(--second);
  padding: 8px 0;
  display: grid;
  background: var(--header-bg);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 999;

}

.menu2::before {
  content: "";
  position: absolute;
  background: inherit;
  border-top: 1px solid var(--second);
  border-right: 1px solid var(--second);
  top: -7px;
  right: 22px;
  width: 12px;
  height: 12px;
  rotate: -45deg;
}

.dropdown5:hover .menu2 {
  opacity: 1;
  visibility: visible;
}

.menu2 > a {
  padding: 12px 30px;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.menu2 > a:hover {
  color: var(--second);
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.menu_active {
  background: rgb(0,0,0);
  color: #E4B637;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}
.menu_droup_item{
font-family: var(--primary-font-family);
font-size: var(--primary-font-size);
}





/* ------- END */

.logo-icon1 {
  height: 80px;
  width: 80px;
  position: relative;
  object-fit: cover;
  cursor: pointer;
}
.frame-with-logo {
  width: 403px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.location-icon7 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 16px;
}
.select-location1 {
  flex: 1;
  position: relative;
  line-height: 130%;
  text-transform: uppercase;
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
}
.cart-item-frame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 4px;
  max-width: 100%;
}
.set-your-location1 {
  flex: 1;
  position: relative;
  line-height: 130%;
}
.dark-buttons-set {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.button33 {
  position: relative;
  font-size: 10px;
  line-height: 130%;
  font-family: Inter;
  color: #fff;
  text-align: center;
}
.buttons-states2,
.user-frame {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.buttons-states2 {
  cursor: pointer;
  border: 0;
  padding: 7px 12px 7px 8px;
  background-color: #c21f24;
  border-radius: 5px;
  justify-content: flex-end;
  white-space: nowrap;
}
.buttons-states2:hover {
  background-color: #e8454a;
}
.user-frame {
  align-self: stretch;
  justify-content: flex-start;
  gap: 0 20px;
  font-size: 16px;
  color: #fff;
}
.buttons-states1 {
  flex: 1;
  border-radius: 5px;
  background-color: #363636;
  border: 1px solid #424242;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 11px 10px 9px;
  max-width: 100%;
  cursor: pointer;
  text-align: left;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
}
.button34 {
  position: relative;
  line-height: 130%;
  text-transform: uppercase;
}
.buttons-states-dark24 {
  height: 49px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 12px;
  box-sizing: border-box;
}
.button35 {
  position: relative;
  line-height: 130%;
  text-transform: uppercase;
}
.buttons-states-dark25 {
  height: 49px;
  border-radius: 5px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 12px;
  box-sizing: border-box;
}
.button36 {
  position: relative;
  line-height: 130%;
  text-transform: uppercase;
}
.buttons-states-dark26 {
  height: 49px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 12px;
  box-sizing: border-box;
}
.button37 {
  position: relative;
  line-height: 130%;
  text-transform: uppercase;
  white-space: nowrap;
}
.buttons-states-dark27 {
  height: 49px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 12px 0 8px;
  box-sizing: border-box;
}
.basket-icon6 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
@media screen and (max-width: 450px) {
  .basket-icon6 {
    height: 18px;
    width: 18px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
}
.button38 {
  position: relative;
  line-height: 130%;
}
.b11,
.wrapper7 {
  align-items: center;
  justify-content: center;
}
.b11 {
  height: 21px;
  position: relative;
  line-height: 130%;
  display: flex;
}
.wrapper7 {
  margin: 0 !important;
  position: absolute;
  top: -10px;
  right: 0;
  border-radius: 10px;
  background-color: #e5b638;
  display: none;
  flex-direction: column;
  padding: 0 5px;
  z-index: 2;
  color: #000;
}
.buttons-states-dark28 {
  height: 49px;
  border-radius: 56px;
  border: 3px solid #e5b638;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px 0 16px;
  position: relative;
  gap: 0 5px;
}
.user-icon1 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.button39 {
  width: 10px;
  position: relative;
  line-height: 130%;
  display: none;
  z-index: 1;
}
.b12,
.wrapper8 {
  align-items: center;
  justify-content: center;
}
.b12 {
  height: 21px;
  position: relative;
  line-height: 130%;
  display: flex;
}
.wrapper8 {
  margin: 0 !important;
  position: absolute;
  top: -10px;
  right: 0;
  border-radius: 10px;
  background-color: #e5b638;
  display: none;
  flex-direction: column;
  padding: 0 5px;
  z-index: 2;
  color: #000;
}
.buttons-states-dark29 {
  align-self: stretch;
  border-radius: 56px;
  border: 3px solid #e5b638;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px 0 16px;
  position: relative;
  gap: 0 10px;
}
.john-smith2 {
  height: 21px;
  width: 95px;
  position: relative;
  line-height: 130%;
  text-transform: uppercase;
  font-family: Poppins;
  display: none;
  white-space: nowrap;
}
.location-frame-container {
  height: 49px;
  justify-content: flex-end;
  gap: 0 5px;
}
.beverages-menu,
.header2,
.location-frame-container,
.select-location-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.beverages-menu {
  height: 63px;
  justify-content: flex-end;
  gap: 0 20px;
  font-family: Inter;
}
.header2,
.select-location-text {
  flex-shrink: 0;
  max-width: 100%;
}
.select-location-text {
  width: 957px;
  justify-content: flex-end;
  gap: 0 30px;
}
.header2 {
  align-self: stretch;
  height: 100px;
  background: radial-gradient(50% 50%at 50% 50%, #252525, #380000);
  box-shadow: 0 4px 16.8px rgba(255, 255, 255, 0.14);
  overflow: hidden;
  justify-content: center;
  padding: 32px 40px;
  box-sizing: border-box;
  top: 0;
  z-index: 99;
  position: sticky;
  text-align: center;
  font-size: 16px;
  color: #fff;
  font-family: Poppins;
}
@media screen and (max-width: 1350px) {
  .user-frame {
    display: none;
  }
}


/* Media queries for different viewport widths */
/* Media queries for different viewport widths */
@media (max-width: 576px) {
  .responsive-image {
    height: 35px;
    width: 35px;
  }
  .basket-view{
    height: 10px;
  } 
}

@media (min-width: 577px) and (max-width: 768px) {
  .responsive-image {
    height: 35px;
    width: 35px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .responsive-image {
    height: 35px;
    width: 35px;
  }
  .basket-view{
    height: 10px;
  } 
}

@media (min-width: 993px) and (max-width: 1200px) {
  .responsive-image {
    height: 35px;
    width: 35px;
  }
  .basket-view{
    height: 49px;
  } 
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .responsive-image {
    height: 35px;
    width: 35px;
  }
}

/* Example of another breakpoint */
@media (min-width: 1401px) {
  .responsive-image {
    height: 35px;
    width: 35px;
  }
}
/* Hove nav bar color change  */

.menu-item {
  transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.menu-item:hover {
  /* background-color: #424242;
  border-color: #424242;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); */
}

.menu-text {
  color: #fff;
  font-weight: 550;
  font-size: var(--primary-font-size);
  /* font-family: 'Montserrat Medium', sans-serif;  */
  font-family: var(--primary-font-family);
  /* font-family: var(--primary-font-family); */
  letter-spacing: 1px; /* Add text spacing */
}

.menu-item.selected-route .menu-text,
.menu-item:hover .menu-text {
  color: #E4B637;
}

.menu-item.selected-route .menu-text::after {
  transform: scaleX(1);
}

.menu-text::after {
  content: '';
  position: absolute;
  bottom: -7px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #E4B637;
  transform: scaleX(0);
  transition: transform 0.4s;
  transform-origin: left; 
}

.menu-item:hover .menu-text::after {
  transform: scaleX(1);
}


/* hover effect for droupdown nav */

.about-us-text {
  position: relative;
  color: #fff;
  font-weight: 550;
  font-size: var(--primary-font-size);
  /* font-family: 'Montserrat Medium', sans-serif;  */
  /* font-family: 'Playfair Display', serif;  */
  /* font-family: 'SF New Republic Bold', sans-serif; */
  /* font-family: 'Gotham Medium', sans-serif; */
  font-family: var(--primary-font-family);
  letter-spacing: 1px; /* Add text spacing */
}

.about-us-text::after {
  content: '';
  position: absolute;
  bottom: -7px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #E4B637;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease-out;
}

.about-us-text:hover::after {
  transform: scaleX(1);
}

.selected-route .about-us-textt,
.about-us-text:hover .about-us-text {
  color: #E4B637;
}

.selected-route .about-us-text::after {
  transform: scaleX(1);
}

.about-us.selected-route .about-us-text,
.about-us:hover .about-us-text {
  color: #E4B637;
}

.about-us.selected-route .about-us-text::after {
  transform: scaleX(1);
}
