@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
body {
  margin: 0;
  line-height: normal;
  background-color: var(--website-bg);
  color: #fff;
  font-family: var(--primary-font-family);
}

/* variables  start */
:root {
  --website-bg: #0d0e11;
  --card-bg: #222326;
  --green-tint: #588b3d;
  --hp-yellow-500: #e4b637;
  --hp-yellow-600: #e4b637;
  --hp-grey-500: #505050;
  --hp-grey-600: #222326;
  --hp-grey-bg-primary: #424242;
  --hp-btn-primary: #c21f24;
  --hp-btn-secondary: #e5b638;
  --border-color: #c1c1c1;
  --text-white: #ffffff;
  --navbar-desk-height: 80px;
  --navbar-mob-height: 57px;
  --primary-font-size-mini: 14px;
  --primary-font-size-sm-mini:12px;
  --primary-font-size-xs-mini:10px;
  --primary-font-size: 16px;
  --header-font-size: 26px;
  --header-font-size-m: 18px;
  --sub-header-font-size: 24px;
  --primary-font-family: GothamLight;
  --primary-font-family-bold: GothamBold;
  --secondary-font-family: GothamBold;
  --icon-size: 20px;
  --description: #c6c8c8;
}
@font-face {
  font-family: MontserratMedium;
  src: url(./assets/fonts/MontserratMedium.ttf);
}
@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: GothamMedium;
  src: url(./assets/fonts/GothamMedium.ttf);
}
@font-face {
  font-family: GothamBold;
  src: url(./assets/fonts/GothamBold.otf);
}
@font-face {
  font-family: GothamLight;
  src: url(./assets/fonts/GothamLight.otf);
}
@font-face {
  font-family: Playfair_Reg;
  src: url(./assets/fonts/PlayfairDisplay-Regular.ttf);
}

@font-face {
  font-family: Playfair_Italic;
  src: url(./assets/fonts/PlayfairDisplay-Italic.ttf);
}
/* font-family: 'Montserrat Medium', sans-serif;  */
/* font-family: 'Playfair Display', serif;  */
/* font-family: 'SF New Republic Bold', sans-serif; */
/* font-family: 'Gotham Medium', sans-serif; */
/* 'Bodoni Bd BT', serif; */

/* variables  end */

/* custom scrollbar start */
/* width */
body::-webkit-scrollbar {
  font-family: var(--primary-font-family);
  display: none;
}
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e8454a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.w-full.flex.py-4.overflow-x-auto::-webkit-scrollbar {
  display: none;
}
.recomen-qick-links::-webkit-scrollbar {
  display: none;
}
/* custom scrollbar end */

.buttons-states-dark61:hover {
  background-color: var(--hp-btn-primary);
  transition: background-color 0.4s ease;
  transition: border-radius 0.4s ease;
  border: 1px solid var(--hp-yellow-600);
  box-sizing: border-box;
  border-radius: 10px;
}
.property-1filledprimary:hover {
  background-color: var(--hp-grey-bg-primary);
  border: 1px solid var(--hp-grey-bg-primary);
  box-sizing: border-box;
}
.buttons-states-dark24:hover {
  background-color: var(--hp-grey-bg-primary);
  border: 1px solid var(--hp-grey-bg-primary);
  box-sizing: border-box;
}
.buttons-states-dark26:hover {
  background-color: var(--hp-grey-bg-primary);
  border: 1px solid var(--hp-grey-bg-primary);
  box-sizing: border-box;
}
.buttons-states-dark27:hover {
  background-color: var(--hp-grey-bg-primary);
  border: 1px solid var(--hp-grey-bg-primary);
  box-sizing: border-box;
}
.buttons-states-dark28:hover {
  background-color: var(--hp-yellow-600);
  border: 1px solid var(--hp-yellow-600);
  box-sizing: border-box;
}
.buttons-states-dark29:hover {
  background-color: var(--hp-yellow-600);
  border: 1px solid var(--hp-yellow-600);
  box-sizing: border-box;
}

.navbar--wrap {
  /* height: 100px; */
  padding: 10px 10px;
  position: relative;
  z-index: 6;
}

.submenu {
  background-color: #333;
  color: var(--text-white);
  display: flex;
  justify-content: space-around;
  position: absolute;
  width: 100%;
  top: var(--navbar-desk-height);
  transform: translateY(-100px);
  transition: all 0.3s ease-out;
  overflow: hidden;
  z-index: 1;
  height: auto;
}

.submenu.open {
  z-index: 5;
  transform: translateY(0);
}
.submenu a {
  padding: 20px;
  z-index: 2;
}
.submenu a:hover {
  background-color: var(--hp-grey-500);
}
.hamburger-sub {
  /* display: flex; */
  gap: 4px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 20px;
  height: 30px;
  overflow: visible;
  margin: auto;
}
/* .hamburger-sub:hover {
  background-color: var(--hp-yellow-500);
} */
.hamburger-sub:focus {
  outline: none;
  border: none;
}
.burger-line {
  width: 100%;
  height: 2px;
  background-color: var(--text-white);
  transition: all 0.3s ease-in-out;
}
.bl-open-w {
  width: 100%;
  display: flex;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
}
.bl-open-w .burger-line {
  transition: all 0.3s ease;
  width: 33.3%;
}

.hamburger-sub.sub-open .bl-open-w .burger-line {
  width: 20%;
}
.hamburger-sub.sub-open .bl-open-w .burger-line {
  width: 4px;
  height: 4px;
  border-radius: 10px;
}

.header-right-action--w {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
@media (max-width: 1024px) {
  .hamburger-sub {
    display: flex; /* Show the button on mobile screens */
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media (max-width: 764px) {
  .hamburger-sub {
    display: flex; /* Show the button on mobile screens */
    margin-left: 0px;
    margin-right: 0px;
  }
  .pdp-list-container {
    max-height: 100vh;
  }
}
@media (min-width: 1024px) {
  .hamburger-sub {
    display: none;
  }
}

/* slider */
.review-section-frame .slick-next {
  right: 56px;
  z-index: 1;
}
.review-section-frame .slick-prev {
  left: 25px;
  z-index: 1;
}
.review-section-frame .slick-slide img {
  display: block;
  width: 100%;
  z-index: 1;
  position: relative;
  /* height: 70vh; */
  object-fit: contain;
  /* background-position: center; */
}

.review-section-frame .slider-item {
  position: relative;
  color: var(--text-white);
  font-size: 20px;
  border-radius: 20px;
}
.review-section-frame .order-action {
  border: 2px solid var(--hp-yellow-500);
  padding: 10px 20px;
  margin-top: 40px;
}
.review-section-frame .basket-button2 {
  margin: -75px auto auto;
}
.review-section-frame .order-action {
  border: 2px solid var(--hp-yellow-500);
  padding: 10px 20px;
  margin-top: 20px;
  transition: all 0.2s ease-in;
}
.review-section-frame .order-action:hover {
  background-color: var(--hp-yellow-500);
}
.review-section-frame .slick-prev:before,
.review-section-frame .slick-next:before {
  content: "";
  background-image: url(./assets/icons/arrow-icon.svg);
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
  font-family: "slick";
  font-size: 40px;
  line-height: 1;
  opacity: 0.75;
  color: rgba(255, 255, 255, 0.5);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  filter: invert(1);
  border: 1px solid #000;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  opacity: 0.3;
  transition: all 0.3s ease;
}
.review-section-frame .slick-prev:hover:before,
.review-section-frame .slick-next:hover:before {
  opacity: 1;
}
.review-section-frame .slick-prev:before {
  transform: rotate(180deg);
}
.banner-action--wrapper {
  position: absolute;
  min-width: 638px;
  min-height: 232px;
  /* background: rgba(14, 14, 14, 0.278); */
  top: 30%;
  left: 10%;
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 24px;
  z-index: 9;
}

.basket-item-card .prod-amount {
  font-family: var(--primary-font-family);
  font-size: var(--primary-font-size);
}

/* common classes */
.fp-container-main {
  max-width: 100%;
}
.fp-wrapper-main {
  max-width: 90%;
  margin: 40px auto;
}

.sec-info--w {
  display: flex;
  justify-content: space-between;
}
.side--w {
  flex: 0 0 48%;
  max-width: 50%;
}
.side--w.w-full {
  flex: 0 0 100%;
  max-width: 100%;
}

.fp-para-section-title-sm {
  font-size: var(--primary-font-size);
}

.fp-para-section-title-md {
  font-size: 30px;
}
.fp-para-section-title {
  font-size: var(--header-font-size);
  font-weight: 700;
  width: max-content;
}

.fp-para-section-title.bottom-a-line:after {
  content: " ";
  display: block;
  width: 100%;
  height: 1.5px;
  border-radius: 10px;
  background-color: var(--hp-yellow-500);
  animation-name: titleBottomLine;
  /* animation-duration: 10s; */
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
}

@keyframes titleBottomLine {
  from {
    width: 2%;
  }
  to {
    width: 100%;
  }
}

.fp-form-common {
  width: 440px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  border: 1px solid var(--hp-grey-600);
  border-radius: 10px;
  background-color: var(--hp-grey-600);
}

.fp-form-common .fp-input-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
}

.fp-input-w {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fp-form-common .user-field-input-common {
  width: 100%;
  height: 49px;
  padding-left: 20px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  background-color: transparent;
  font-size: 12px;
}
.fp-form-common .user-field-textarea-common {
  width: 100%;
  padding-left: 20px;
  padding-top: 10px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  background-color: transparent;
  font-family: var(--primary-font-family);
  font-size: 12px;
}

.fp-form-common .user-field-select-common {
  padding-right: 20px;
  background-color: #222326;
}

.fp-primary-btn {
  background-color: var(--hp-btn-primary);
  width: 100%;
  height: 45px;
  border-radius: 5px;
}
.file-upload-w {
  position: relative;
  padding: 20px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
}
.fp-input-file {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.file-u-overlay {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: var(--hp-grey-500);
}
.on-mob-vis,
.submenu-divider {
  display: none;
}

.pi-row {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.pi-row .pi-each {
  /* height: 190px; */
  display: flex;
  width: calc(50% - 15px);
  background-color: var(--hp-grey-600);
  border-radius: 10px;
  gap: 20px;
}
.menu-side-option-c {
  display: flex;
  flex-direction: column;
}
.menu-store-info {
  background-color: #363636;
  padding: 30px;
  border-radius: 10px;
  margin-top: 24px;
}
.pdp-list-container {
  overflow-y: auto;
  padding-right: 10px;
  margin-bottom: 20px;
  max-height: calc(100vh + 200px);
}
.menu-action-cart-btn {
  border: 1px solid var(--hp-yellow-600);
  font-family: var(--primary-font-family-bold);
  font-size: var(--primary-font-size-mini);
  border-radius: 6px;
  padding: 9px;
  width: 25%;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}
.menu-action-cart-btn:hover {
  background: var(--hp-btn-primary);
}
.menu-action-cart-btn:focus {
  outline: none;
}
/* w-[30%] hidden sm:hidden md:hidden lg:flex  */
.menu-side-filter-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
/* bg-[#363636] rounded-[20px] w-[25%] py-5 pl-6 pr-12 gap-4 flex flex-col absolute top-[10vh] left-[2%] */
.menu-side-filter-wrapper {
  background-color: #4c4c4c;
  padding: 30px;
  border-radius: 16px;
  margin-top: 20px;
}
/* font-poppins font-semibold text-3xl text-[#E5B638] ml-3 */
.menu-locaion-detail-title {
  display: flex;
  font-size: 20px;
}
.menu-side-filter-list-w {
  margin-top: 20px;
}

/* flex items-center gap-[10px]  */
.filter-option-lis {
  display: flex;
}
.para-container {
  padding-right: 10px;
  padding-left: 10px;
}
.slick-slider .slick-dots li button:before {
  color: rgb(191, 191, 191);
}
.slick-slider .slick-dots li.slick-active button:before {
  color: rgb(225, 12, 12);
}
.home-location.category-2-wrapper {
  padding: 20px;
  border-radius: 10px;
  background-color: var(--card-bg);
}
.home-location-side-img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}
.hf-row {
  margin: auto;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .review-section-frame .slick-prev:before,
  .review-section-frame .slick-next:before {
    width: 30px;
    height: 30px;
  }
  .contact-us-side-img {
    height: 100%;
    background-size: cover;
    object-fit: cover;
  }
  .home-location-side-img {
    height: 100%;
    background-size: cover;
    object-fit: cover;
    background-position: center left;
  }
  .dark-mode-toggle3,
  .social-media-icons,
  .whats-app-images {
    flex-direction: column;
  }
  .whats-app-images .instagram-dark-wrapper {
    display: flex;
  }
  .whats-app-images .instagram-dark-wrapper .fotter-text {
    font-size: 12px;
  }
  .instagram-dark,
  .instagram-dark-wrapper {
    align-items: center;
    justify-content: center;
  }
  .sec-info--w {
    flex-direction: column;
    gap: 20px;
  }
  .instagram-dark-parent {
    justify-content: center;
  }
  body .submenu {
    top: calc(var(--navbar-desk-height) + 6px);
  }
}
@media (min-width: 700px) and (max-width: 1024px) {
  .sec-info--w {
    flex-direction: row;
    gap: 20px;
}
}

@media (max-width: 1024px) {
  .hf-row {
    flex: 0 0 95%;
    max-width: 90%;
    margin: auto;
  }
  .menu-side-filter-options {
    display: none;
  }
  .m-filter-toggle-btn {
    margin-top: 20px;
    z-index: 3;
  }
  body .m-filter-toggle-btn:focus-visible,
  body .m-filter-toggle-btn:focus {
    outline: none;
    border: none;
  }
  .filter-menu-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .filer-available #sidenav-1 {
    border-top-left-radius: 0;
  }
  .portalPopupOverlay .background-frame {
    /* border: none; */
    height: auto;
  }
  .portalPopupOverlay .wo-addon {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    max-width: 100%;
  }
  .dark-mode-parent {
    align-items: center;
    justify-content: center;
  }
  .button-group-frame,
  .buttons-frame1,
  .dark-mode-image-frames {
    align-items: center;
  }
  .buttons-frame1 {
    justify-content: center;
    gap: 10px;
  }
  .copyright-thin-frame,
  .recieve-updates-frame {
    justify-content: center;
  }
  .what-we-believe4.fp-para-section-title {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .sec-info--w {
    flex-direction: column;
  }
  .sec-info--w.info_event {
    flex-direction: column-reverse;
    margin-top: 20px;
  }
  .menu-items-list {
    /* gap: 0; */
  }
  .pi-container {
    gap: 15px;
  }
}

/* responsive css */
@media (max-width: 768px) {
  .hf-row {
    flex: 0 0 95%;
    max-width: 90%;
    margin: auto;
  }
  .slick-dots {
    display: none !important;
  }
  .side--w {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .pi-items-list {
    gap: 15px;
  }
  .banner-top {
    height: 130px;
  }
  .para-container {
    margin-top: 20px;
  }
  .sub-pages-forms {
    margin-left: 0;
  }
  .menu-side-option-c {
    display: none;
  }
  .fp-wrapper-main {
    margin: 16px auto;
  }
  .dy-font-15 {
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 1px;
    font-weight: normal;
  }
  nav#sidenav-1 {
    width: 54%;
    top: 88px;
    margin: auto;
    left: 0px;
    height: -moz-fit-content;
    height: fit-content;
    background: #262525fc;
    border-radius: 10px;
    z-index: 1;
  }
  .mob-menu-filter-option {
    z-index: 2;
    margin-top: 24px;
  }
  .pi-row .pi-each {
    /* height: 190px; */
    width: 100%;
    gap: 20px;
  }
  .pi-row .pi-item-name {
    width: 85%;
  }
  .on-mob-vis,
  .submenu-divider {
    display: block;
  }
  .submenu-divider {
    background-color: var(--hp-btn-secondary);
    width: 20%;
    display: block;
    height: 2px;
  }
  .h-link.menu-btn,
  .h-link.location-btn {
    color: var(--hp-btn-secondary);
  }
  .h-link.menu-btn {
    width: 50%;
    border: 1px solid var(--hp-btn-secondary);
    border-radius: 10px;
  }
  [data-collapse-toggle="mobile-menu-2"] {
    display: none;
  }
  .banner-action--wrapper {
    position: absolute;
    min-width: 189px;
    width: 100%;
    min-height: 100%;
    top: 0%;
    left: 3%;
    font-size: 17px;
    font-family: var(--primary-font-family);
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-bottom: 29%;
  }
  .basket-button2 {
    border-radius: 17px;
    border: none;
  }
  .ante-eget-vel6,
  .ante-eget-vel7,
  .lorem-ipsum-dolor4,
  .we-believe-in1 {
    font-size: 17px;
    font-family: var(--primary-font-family);
  }
  .review-section-frame .slick-prev:before,
  .review-section-frame .slick-next:before {
    font-size: 14px;
    font-family: var(--primary-font-family);
    width: 23px;
    height: 23px;
  }
  .fp-para-section-title {
    font-size: var(--header-font-size-m);
    line-height: normal;
    text-wrap: wrap;
    max-width: 274px;
  }
  .what-we-believe-frame {
    height: auto;
  }
  .button79 {
    gap: 0;
  }
  .submenu {
    width: 100%;
    top: var(--navbar-mob-height);
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(-600px);
    transition: all 0.5s ease-out;
    padding-bottom: 20px;
  }
  .submenu a {
    width: 100%;
    text-align: center;
    padding: 10px;
  }

  .review-form {
    width: 100%;
    font-size: 13px;
    font-family: var(--primary-font-family);
  }

  .view-all-stores2 {
    font-size: 12px;
    font-family: var(--primary-font-family);
    line-height: normal;
  }
  .location-pin-map {
    position: relative;
    height: 175px;
    border-radius: 10px;
  }
  .location-pin-map img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .location-pin-map .mappin-icon4,
  .location-pin-map .mappin-icon5,
  .location-pin-map .mappin-icon6,
  .location-pin-map .mappin-icon7 {
    height: 10px;
    width: 10px;
    position: absolute;
    z-index: 1;
  }
  .location-pin-map .mappin-icon4 {
    top: calc(100% - 61px);
    left: calc(100% - 310px);
  }
  .location-pin-map .mappin-icon5 {
    top: calc(100% - 53px);
    left: calc(100% - 233px);
  }
  .location-pin-map .mappin-icon6 {
    top: calc(100% - 83px);
    left: calc(100% - 242px);
  }
  .location-pin-map .mappin-icon7 {
    top: calc(100% - 91px);
    left: calc(100% - 124px);
  }
  .homepage1 .location1 {
    gap: 10px 0;
  }
  .homepage1 .frame-parent65 .category-2-wrapper {
    width: 100%;
    margin: 2px !important;
    padding: 17px !important;
    border-radius: 10px !important;
  }
  .homepage1 .frame-parent65 {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .homepage1 .map-pin-section {
    padding-bottom: 5px;
  }
  .homepage1 .category-2-wrapper .button80,
  .homepage1 .category-2-wrapper .button81 {
    font-size: 14px;
    font-family: var(--primary-font-family);
  }
  .call-parent4,
  .category-2-inner2,
  .frame-parent64 {
    align-items: center;
  }
  .call-parent4 .call-icon7,
  .location-parent5 .location-icon14 {
    height: var(--icon-size);
    width: var(--icon-size);
  }
  .call-parent4 .div36,
  .location-parent5 .hospital-st-fort7 {
    font-size: 12px;
    position: relative;
    display: inline-block;
    min-width: auto;
    max-width: 70%;
  }
  .buttons-states-dark67 {
    background-color: var(--hp-yellow-500);
    margin-bottom: 15px;
    gap: 0 10px;
  }
  .map-pin-section {
    gap: 10px 0;
  }
  .customer-service-man-answering {
    /* height: 352px;
    width: 325px; */
    top: 0;
    left: 0%;
  }
  .give-your-feedback-below {
    height: 220px;
  }
  .v-i-p-frame {
    /* width: 1066px; */
    width: 100%;
    padding: 24px;
  }
  .update-notifications-frame {
    gap: 15px;
  }
  .buttons-states-dark61 {
    width: 100%;
  }
  .button73,
  .recieve-updates-on {
    font-size: var(--primary-font-size-mini);
    line-height: normal;
  }
  .instagram-facebook-mail-frame {
    padding: 13px 0 0;
  }

  .buttons-states-dark61 {
    border: 1px solid var(--hp-yellow-600);
  }
  .lorem-ipsum-dolor-container3 {
    font-size: 15px;
  }
  .ante-eget-vel6,
  .ante-eget-vel7,
  .lorem-ipsum-dolor4,
  .we-believe-in1 {
    font-size: 15px;
  }
  .fp-main--contact-us .contact-us-side-img {
    height: 100%;
    object-fit: cover;
  }
  .locationinstances .locationinstances1 {
    padding: 0;
  }
  .locationinstances img.image-5-icon {
    width: 100%;
    height: 100%;
    position: initial;
  }
  .nearbystorestext {
    padding: 0 20px;
  }
  .nearbystorestext .categoryinstance .frame-parent65 {
    display: flex;
    flex-direction: column;
  }
  .review-section-frame .slick-next {
    right: 34px;
  }
  .review-section-frame .basket-button2 {
    margin: 23px auto auto;
    padding: 23px;
    z-index: 0;
  }
  .ordersframe {
    width: 100%;
    margin-top: 22px;
  }
  .summary2 {
    min-height: 140px;
    max-height: 210px;
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    flex: none;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
    padding: 20px;
  }
  .john-smithtext-icon {
    width: 100px;
    height: 100px;
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
    border-radius: 100px;
    flex: auto;
    align-self: center;
  }
  .summarydetails {
    width: fit-content;
  }
  .john-smith1 {
    font-size: 16px;
    line-height: normal;
  }
  .frame-button-dropdown {
    row-gap: 20px;
  }
  .quantity-frame {
    flex-wrap: nowrap;
}
}

@media (max-width: 600px) {
  .delivered-icon {
    flex-direction: column;
  }
  .delivered-icon .my-orders1 {
    width: 100%;
  }
  .header-right-action--w .responsive-font-size {
    display: none;
  }
  .menu-top-banner {
    height: 100px;
    object-fit: cover;
  }
  .sticky-top-wrapper {
    top: 67px;
  }
  .what-we-believe4.fp-para-section-title {
    margin-bottom: 10px;
  }
  .what-we-believe4.bottom-a-line {
    margin-top: 10px;
    font-size: var(--primary-font-size);
  }
  .ginger-frame,
  .inputfieldframe {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: -20px;
    margin-right: 40px;
  }
  .delete-icon {
    width: 24px;
    height: 24px;
    position: relative;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    bottom: 28px;
    right: 18px;
  }

  .prod-select-qty {
    width: 107px;
  }

  .basket-item-card {
    position: relative;
  }
  .basket-item-card .input-field1 {
    font-size: 30px;
  }
  .basket-item-card .prod-amount {
    font-size: var(--primary-font-size);
  }
  .edoneil-avvd-zlh-dow-aunsplash11 {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@media (max-width: 1000px) {
  .john-smithtext-icon {
    width: 100px;
    height: 100px;
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
    border-radius: 100px;
    flex: auto;
    align-self: center;
  }
  .ordersummary .summary2 {
    height: 190px;
    flex-direction: row;
    gap: 20px;
    padding: 26px;
  }
  .ordersummary .rightframe {
    gap: 20px;
  }
  .ordersummary .rightframe .summary3 {
    margin-top: 0;
  }
}

@media  (max-width: 764px) {
  .home-location.category-2-wrapper{
    padding:10px;
  }
}


