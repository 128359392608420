.my-address1 {
  width: 100%;
  position: relative;
  background-color: #252525;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 226px;
  box-sizing: border-box;
  gap: 40px 0;
  letter-spacing: normal;
}
@media screen and (max-width: 800px) {
  .my-address1 {
    gap: 40px 0;
  }
}
