.epback-icon {
  margin-right: 10px;
  width: 25px;
  height: 25px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
  
}
.blank-text-box {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 120%;
  
}
.header-divider {
  
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: flex-start;
  /* gap: 20px 0; */
}
.order-details {
  width: 183px;
  position: relative;
  line-height: 130%;
  font-weight: 300;
  display: flex;
  align-items: flex-end;
}
.divider8 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-top: 2px solid #e6e6e6;
  box-sizing: border-box;
  opacity: 0.45;
}
.divider-line {
  align-self: stretch;
  height: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}
.empty-space-frame,
.my-orders-frame,
.orders1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.my-orders-frame {
  align-self: stretch;
  align-items: flex-start;
  padding: 0 0 6px;
  gap: 26px 0;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.6);
}
.empty-space-frame,
.orders1 {
  box-sizing: border-box;
}
.empty-space-frame {
  width: 80%;
  align-items: flex-start;
  padding: 0 20px;
  gap: 20px 0;
  max-width: 100%;
  text-align: left;
  font-size: 36px;
  color: #fff;
  font-family: Poppins;
  margin-top: 20px;
}
.orders1 {
  width: 100%;
  position: relative;
  background-color: #252525;
  overflow: hidden;
  align-items: center;
  padding: 0 0 162px;
  gap: 40px 0;
  letter-spacing: normal;
}
@media screen and (max-width: 800px) {
  .blank-text-box {
    font-size: 29px;
    line-height: 35px;
  }
  .orders1 {
    gap: 40px 0;
  }
}
@media screen and (max-width: 450px) {
  .blank-text-box {
    font-size: 22px;
    line-height: 26px;
  }
  .order-details {
    font-size: 16px;
    line-height: 21px;
  }
}
