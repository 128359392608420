.basket1 {
  margin: 0;
  position: relative;
  font-size: var(--header-font-size);
  font-family: var(--primary-font-family-bold);
  letter-spacing: -0.02em;
  line-height: 25px;
  font-weight: 400;
  font-family: inherit;
}
.items1 {
  font-size: var(--primary-font-size-mini);
  font-family: var(--primary-font-family);
  position: relative;
  font-size: 20px;
  line-height: 14px;
  font-weight: 300;
}
.item-list-frame,
.summary-frame {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.item-list-frame {
  align-items: flex-start;
  gap: 0 10px;
}
.summary-frame {
  width: 1320px;
  align-items: flex-start;
  padding: 0 20px;
  box-sizing: border-box;
  max-width: 100%;
}
.divider9 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-top: 2px solid #e6e6e6;
  box-sizing: border-box;
  opacity: 0.45;
}
.cart-item-rectangle {
  width: 1280px;
  height: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 100%;
}
.basket,
.user-instance-parent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
}
.user-instance-parent {
  width: 1320px;
  align-items: flex-start;
  padding: 0 20px;
  gap: 31px 0;
  max-width: 100%;
}
.basket {
  width: 100%;
  position: relative;
  background-color: #252525;
  overflow: hidden;
  align-items: center;
  padding: 40px 0 40px;
  gap: 40px 0;
  letter-spacing: normal;
  text-align: left;
  font-size: 36px;
  color: #fff;
  font-family: Poppins;
  min-height: 100vh;
}
@media screen and (max-width: 1350px) {
  .cart-item-rectangle {
    width: calc(100% - 40px);
  }
}
@media screen and (max-width: 800px) {
  .basket1 {
    font-size: 29px;
    line-height: 35px;
  }
  .user-instance-parent {
    gap: 31px 0;
  }
  .basket {
    gap: 40px 0;
  }
}
@media screen and (max-width: 450px) {
  .basket1 {
    font-size: 22px;
    line-height: 26px;
  }
  .items1 {
    font-size: 16px;
    line-height: 21px;
  }
}

.sticky-top-wrapper-basket {
  position: sticky;
  top: 80px; /* Stick to the top of the viewport */
  z-index: 1; /* Adjust z-index as needed */
  background-color: #252525; /* Add background color if needed */
}

