.checkoutshippingaddress1 {
  width: 100%;
  position: relative;
  background-color: var(--website-bg);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 209px;
  box-sizing: border-box;
  gap: 40px 0;
  letter-spacing: normal;
}
@media screen and (max-width: 800px) {
  .checkoutshippingaddress1 {
    gap: 40px 0;
  }
}
