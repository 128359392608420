.ed-o-neil-avvdzlhdowa-unsplash-icon18 {
  height: 160px;
  width: 160px;
  position: relative;
  object-fit: cover;
}
.chole-batura12,
.div31 {
  font-size: var(--primary-font-size);
font-family: var(--primary-font-family-bold);


  position: relative;
  line-height: 130%;
}
.chole-batura12 {
  font-weight: 600;
}
.div31 {
  font-size: var(--primary-font-size);
  font-family: var(--primary-font-family-bold);


  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
}
.chole-batura-parent3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px 0;
}
.icon,
.qty {
  position: relative;
}
.qty {
  line-height: 130%;
}
.icon {
  height: 32px;
  width: 32px;
  object-fit: cover;
}
.input-field {
  width: 128px;
  border-radius: 20px;
  background-color: #444;
  border: 2px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 10px 4px 14px;
  gap: 20px;
  white-space: nowrap;
  cursor: pointer;
  font-size: 16px;
}
.input-field4 {
  width: 128px;
  border-radius: 20px;
  background-color: #444;
  border: 2px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 10px 4px 14px;
  gap: 20px;
  white-space: nowrap;
  cursor: pointer;
  font-size: 16px;
}
.button-text,
.location-instance {
  font-size: var(--primary-font-size);
font-family: var(--primary-font-family-bold);
color: var(--hp-yellow-600);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.location-instance {
  gap: 20px 0;
}
.button-text {
  font-size: var(--primary-font-size);
  font-family: var(--primary-font-family-bold);
  color: var(--hp-yellow-600);
  padding: 0 0 9px;
  box-sizing: border-box;
  min-width: 134px;
}
.edoneil-avvd-zlh-dow-aunsplash11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 24px;
  min-width: 318px;
}
.input-field1 {
  position: relative;
  line-height: 130%;
  font-weight: 600;
  white-space: nowrap;
}
.delete-icon,
.input-field2 {
  overflow: hidden;
  flex-shrink: 0;
}
.delete-icon {
  width: 24px;
  height: 24px;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
}
.input-field2 {
  width: 34px;
  height: 32px;
  border-radius: 20px;
  background-color: #c60303;
  border: 2px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 4px 7px 4px 3px;
}
.ginger-frame,
.inputfieldframe {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.inputfieldframe {
  gap: 63px 0;
}
.ginger-frame {
  width: 267px;
  min-width: 267px;
  text-align: right;
  margin-right: 30px;
}
.tomato {
  align-self: stretch;
  border-radius: 20px;
  background-color: #363636;
  /* overflow: hidden; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 0;
  gap: 20px;
  text-align: left;
  font-size: 20px;
  color: #fff;
  font-family: Poppins;
}
@media screen and (max-width: 800px) {
  .edoneil-avvd-zlh-dow-aunsplash11,
  .ginger-frame {
    flex: 1;
  }
  .tomato {
    flex-wrap: wrap;
    padding: 20px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .ed-o-neil-avvdzlhdowa-unsplash-icon18 {
    flex: 1;
  }
  .chole-batura12 {
    font-size: 16px;
    line-height: 21px;
  }
  .button-text {
    flex: 1;
  }
  .edoneil-avvd-zlh-dow-aunsplash11 {
    flex-wrap: wrap;
  }
  .input-field1 {
    font-size: 16px;
    line-height: 21px;
  }
}
