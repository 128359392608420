.sign-in1 {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.sign-in-wrapper {
  width: 530px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.a-few-more1 {
  flex: 1;
  position: relative;
  display: inline-block;
  max-width: 100%;
}
.a-few-more-questions-to-help-b-wrapper {
  align-self: stretch;
  flex-direction: row;
  font-size: 18px;
  color: #fff;
}
.a-few-more-questions-to-help-b-wrapper,
.signin-text-wrapper,
.signin-text1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.signin-text1 {
  flex: 1;
  flex-direction: column;
  gap: 5px 0;
}
.signin-text-wrapper {
  align-self: stretch;
  flex-direction: row;
  text-align: left;
  color: #e5b638;
}
.frame-child24 {
  width: 157px;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 27px;
  margin: 0 !important;
  position: absolute;
  top: calc(50% - 14px);
  left: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 13px;
  box-sizing: border-box;
  font-family: Poppins;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
}
.input-inner5,
.input10 {
  align-self: stretch;
  /* display: flex; */
  flex-direction: column;
  /* align-items: flex-start; */
  margin-top: 5px;
}
.input-inner5 {
  height: 50px;
  border-radius: 5px;
  /* border: 1px solid #929292; */
  box-sizing: border-box;
  justify-content: flex-start;
  /* padding: 20px; */
  position: relative;
}
.input-inner-group{
  align-self: stretch;
  display: flex;
  /* flex-direction: column; */
  /* align-items: flex-start; */
  margin-top: 5px;
  flex-grow: 1;
  height: 50px;
  border-radius: 5px;
}
.input10 {
  justify-content: center;
}
.o-rvector-icon {
  align-self: stretch;
  max-width: 100%;
  overflow: clip;
  max-height: unset;
}
.o-rvector-icon,
.or1 {
  position: relative;
}
.framewith-o-r {
  width: 65px;
  margin: 0 !important;
  position: absolute;
  left: calc(50% - 31.5px);
  background-color: var(--card-bg);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.o-rvector-parent {
  align-self: stretch;
  height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  font-size: 14px;
}
.sign-up-here1 {
  color: #e5b638;
}
.dont-have-an-container1 {
  width: 560px;
  position: relative;
  font-size: 18px;
  display: inline-block;
  max-width: 100%;
}
.google-icon1 {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  flex-shrink: 0;
}
.button75 {
  position: relative;
  font-size: 18px;
  line-height: 130%;
  font-family: Poppins;
  color: #fff;
  text-align: center;
}
.buttons-states-dark63 {
  cursor: pointer;
  border: 1px solid #9e9e9e;
  padding: 0 12px;
  background-color: #363636;
  align-self: stretch;
  flex: 1;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0 20px;
}
.buttons-states-dark63:hover,
.buttons-states-dark64:hover {
  background-color: #5c5c5c;
  border: 1px solid #6b6b6b;
  box-sizing: border-box;
}
.facebook-icon1 {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  flex-shrink: 0;
}
.button76 {
  position: relative;
  font-size: 18px;
  line-height: 130%;
  font-family: Poppins;
  color: #fff;
  text-align: center;
}
.buttons-states-dark64 {
  cursor: pointer;
  border: 1px solid #9e9e9e;
  padding: 0 12px;
  background-color: #363636;
  align-self: stretch;
  flex: 1;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0 20px;
  width: auto;
}
.category-26,
.signupcalltoaction {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.signupcalltoaction {
  height: 130px;
  gap: 10px 0;
}
.category-26 {
  border-radius: 10px;
  background-color: var(--card-bg);
  overflow: hidden;
  padding: 40px;
  box-sizing: border-box;
  gap: 30px 0;
  max-width: 100%;
  text-align: center;
  font-size: var(--sub-header-font-size);
  color: #fff;
  font-family: var(--primary-font-family);
}
@media screen and (max-width: 800px) {
  .sign-in1 {
    font-size: 29px;
  }
  .category-26 {
    gap: 30px 0;
  }
}
@media screen and (max-width: 450px) {
  .sign-in1 {
    font-size: 22px;
  }
}
