.locationframe {
  flex: 1;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px 0;
  min-width: 524px;
  max-width: 100%;
}
@media screen and (max-width: 800px) {
  .locationframe {
    min-width: 100%;
  }
}
