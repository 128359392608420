.shipping-method-icon {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  flex-shrink: 0;
}
.delivery-partner {
  height: 18px;
  flex: 1;
  position: relative;
  line-height: 130%;
  font-weight: 500;
  display: inline-block;
  min-width: 416px;
  max-width: 100%;
}
.down-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.shipping-method-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 0 10px;
  max-width: 100%;
}
.delivery,
.divider {
  box-sizing: border-box;
  display: none;
}
.divider {
  align-self: stretch;
  height: 1px;
  position: relative;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-top: 1px solid #e6e6e6;
  opacity: 0.45;
}
.delivery {
  width: 748px;
  height: 60px;
  border-radius: 10px;
  background-color: #363636;
  border: 1px solid #545454;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 21px 20px 19px;
  gap: 15px 0;
  max-width: 100%;
}
.checkout {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 120%;
  font-weight: 400;
  font-family: inherit;
}
.please-enter-your {
  position: relative;
  font-size: 20px;
  line-height: 130%;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.6);
}
.tax-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.divider1 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-top: 2px solid #e6e6e6;
  box-sizing: border-box;
  opacity: 0.45;
}
.delivery-frame {
  align-self: stretch;
  height: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}
.checkoutshippingaddress,
.subtotal-frame {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  text-align: left;
  color: #fff;
  font-family: Poppins;
}
.subtotal-frame {
  width: 1320px;
  align-items: flex-start;
  padding: 0 20px;
  gap: 20px 0;
  max-width: 100%;
  font-size: 36px;
}
.checkoutshippingaddress {
  width: 100%;
  position: relative;
  background-color: #252525;
  overflow: hidden;
  align-items: center;
  padding: 0 0 143px;
  gap: 40px 0;
  letter-spacing: normal;
  font-size: 14px;
}
@media screen and (max-width: 800px) {
  .delivery-partner {
    min-width: 100%;
  }
  .delivery {
    height: auto;
  }
  .checkout {
    font-size: 29px;
    line-height: 35px;
  }
  .checkoutshippingaddress {
    gap: 40px 0;
  }
}
@media screen and (max-width: 450px) {
  .checkout {
    font-size: 22px;
    line-height: 26px;
  }
  .please-enter-your {
    font-size: 16px;
    line-height: 21px;
  }
}
