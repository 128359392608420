.profile-icon2 {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}
.contact-information2 {
  flex: 1;
  position: relative;
  line-height: 130%;
  font-weight: 500;
  display: inline-block;
  min-width: 114px;
  max-width: 100%;
}
.down-icon12 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.edoneil-avvd-zlh-dow-aunsplash8 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0 10px;
  max-width: 100%;
  flex-shrink: 0;
}
.divider21 {
  align-self: stretch;
  height: 1px;
  position: relative;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-top: 1px solid #e6e6e6;
  box-sizing: border-box;
  opacity: 0.45;
}
.contact-detail2 {
  position: relative;
  line-height: 130%;
  font-weight: 500;
}
.summary6 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px 0;
  max-width: 100%;
}
.divider22 {
  align-self: stretch;
  height: 1px;
  position: relative;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-top: 1px solid #e6e6e6;
  box-sizing: border-box;
  opacity: 0.45;
}
.button63 {
  position: relative;
  font-size: 12px;
  line-height: 130%;
  font-family: Inter;
  color: #fff;
  text-align: center;
}
.buttons-states-dark51 {
  cursor: pointer;
  border: 1px solid #fff;
  /* padding: 10px 10px 10px 19px; */
  padding:10px;
  background-color: transparent;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.buttons-states-dark51:hover {
  background-color: rgba(230, 230, 230, 0.09);
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
}
.button64 {
  position: relative;
  font-size: 12px;
  line-height: 130%;
  font-family: Poppins;
  color: #fff;
  text-align: center;
}
.buttons-states-dark52 {
  cursor: pointer;
  border: 0;
  padding: 10px;
  background-color: #c21f24;
  width: 90px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.buttons-states-dark52:hover {
  background-color: #e8454a;
}
.buttons-states-dark-parent5,
.contactinformation-inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.buttons-states-dark-parent5 {
  gap: 0 10px;
}
.contactinformation-inner {
  align-self: stretch;
}
.button-state-light,
.contactinformation2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px 0;
  max-width: 100%;
}
.contactinformation2 {
  align-self: stretch;
  border-radius: 10px;
  background-color: var(--card-bg);
  border: 1px solid #545454;
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
  padding: 20px 19px;
}
.button-state-light {
  flex: 1;
  align-items: flex-start;
  min-width: 486px;
  text-align: left;
  font-size: 14px;
  color: #fff;
  font-family: Poppins;
}
@media screen and (max-width: 800px) {
  .edoneil-avvd-zlh-dow-aunsplash8 {
    flex-wrap: wrap;
  }
  .button-state-light {
    min-width: 100%;
  }
}



.visible {
  display: flex;
  gap: 15px;
  transition: all 0.4s ease; /* Add a transition effect for all properties */
}

.hidden {
  display: none;
}