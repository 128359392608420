.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  .modal-body {
    background-size: cover; /* Cover the entire container with the background image */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    /* filter:contrast(.7) brightness(.7);   */
  }
  .modal-content {
    background-color: rgb(54,54,54);
    /* padding: 20px; */
    border-radius: 8px;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 35%;
  }
  /* Media query for smaller screens (e.g., mobile devices) */
@media (max-width: 768px) {
  .modal-content {
    width: 90%; /* Adjust width for smaller screens */
    margin: auto; /* Center the modal horizontally */
  }
}
@media (min-width: 769px) {
  .modal-content {
    width: 60%; /* Adjust width for smaller screens */
    margin: auto; /* Center the modal horizontally */
  }
}
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    z-index: 999;
  }
  
  .modal-body {
    text-align: center;
  }
  
  /* button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  } */
  