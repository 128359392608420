.darkmode-child {
  width: 1669px;
  height: 633px;
  position: absolute;
  margin: 0 !important;
  right: 1px;
  bottom: -55.5px;
  object-fit: contain;
}
.whatsapp-image-2024-01-29-at-1 {
  height: 106px;
  width: 106px;
  position: relative;
  border-radius: 150px;
  object-fit: cover;
}
.whatsapp-image-2024-01-29-at-11 {
  height: 64px;
  width: 322px;
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.copyright-thin-frame,
.recieve-updates-frame {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.recieve-updates-frame {
  gap: 0 10px;
}
.copyright-thin-frame {
  min-width: 409px;
}
.facebook-dark-icon,
.instagram-dark-icon,
.mail-dark-icon {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 40px;
}
.dark-mode-parent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0;
  box-sizing: border-box;
  gap: 0 15px;
  min-width: 409px;
  max-width: 100%;
}
.button-group-frame,
.buttons-frame1,
.dark-mode-image-frames {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.dark-mode-image-frames {
  align-self: stretch;
  flex-wrap: wrap;
  gap: 0 20px;
}
.button-group-frame,
.buttons-frame1 {
  gap: 0 30px;
}
.button-group-frame {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px 0;
  z-index: 1;
}
.phcopyright-thin-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}
.hunger-points-all {
  position: relative;
  line-height: 130%;
}
.darkmode,
.frame-group1 {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.frame-group1 {
  flex-direction: row;
  justify-content: center;
  gap: 0 10px;
  z-index: 1;
}
.darkmode {
  background: radial-gradient(50% 50%at 50% 50%, #252525, #4a0000);
  box-shadow: 0-1px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: 32px 80px;
  box-sizing: border-box;
  position: relative;
  gap: 70px 0;
  max-width: 100%;
  text-align: center;
  font-size: 16px;
  color: #fff;
  font-family: Poppins;
}
@media screen and (max-width: 1125px) {
  .buttons-frame1 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  .recieve-updates-frame {
    flex-wrap: wrap;
  }
  .copyright-thin-frame,
  .dark-mode-parent {
    min-width: 100%;
  }
  .darkmode {
    gap: 70px 0;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .dark-mode-parent {
    flex-wrap: wrap;
    justify-content: center;
  }
  .darkmode {
    gap: 70px 0;
  }
}
