.custom-select {
    position: relative;
  }
  
  .custom-select__selected {
    /* Styles for the selected option display */
  }
  
  .custom-select__options {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    list-style-type: none; /* Remove default list styling */
    padding: 0;
  }
  
  .custom-select__option {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
  }
  
  .custom-select__option-image {
    width: 30px; /* Adjust image width as needed */
    height: auto; /* Maintain aspect ratio */
    margin-right: 10px;
  }
  
  .custom-select__option-text {
    flex: 1; /* Take remaining space */
  }
  
  /* Style for when an option is hovered or selected */
  .custom-select__option:hover,
  .custom-select__option.active {
    background-color: #f0f0f0;
  }
  