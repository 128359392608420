.divider27 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-top: 2px solid #e6e6e6;
  box-sizing: border-box;
  opacity: 0.45;
}
.frame-email-address-input,
.frame-input1 {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.frame-email-address-input {
  height: 0;
  flex: 1;
  align-items: center;
  justify-content: center;
  position: relative;
}
.frame-input1 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 10px;
  box-sizing: border-box;
}
.contact-detail3 {
  position: relative;
  font-size: var(--sub-header-font-size);
  font-family: var(--primary-font-family-bold);



  line-height: 130%;
  
  
  color:var(--hp-yellow-600);
  text-align: left;
}
.text-email-address {
  width: 82px;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 21px;
  margin: 0 !important;
  position: absolute;
  top: calc(50% - 11.5px);
  left: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: Poppins;
  font-size: 14px;
}
.frame-select-state,
.input5 {
  font-size: var(--primary-font-size-mini);
  font-family: var(--primary-font-family);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}
.frame-select-state {
  align-self: stretch;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #929292;
  box-sizing: border-box;
  justify-content: flex-start;
  position: relative;
}
.input5 {
  flex: 1;
  justify-content: center;
  min-width: 255px;
  max-width: 100%;
}
.text-basket {
  width: 81px;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 21px;
  margin: 0 !important;
  position: absolute;
  top: calc(50% - 11.5px);
  left: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: Poppins;
  font-size: 14px;
}
.input6,
.selectstate {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.selectstate {
  align-self: stretch;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #929292;
  box-sizing: border-box;
  position: relative;
}
.input6 {
  flex: 1;
  min-width: 255px;
  max-width: 100%;
}
.frame-button-dropdown {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1px 0 0;
  box-sizing: border-box;
  gap: 0 15px;
  max-width: 100%;
}
.address {
  color: #909090;
}
.span4 {
  color: red;
}
.email-address2 {
  height: 21px;
  position: relative;
  font-size: 14px;
  font-family: Poppins;
  text-align: left;
  display: inline-block;
}
.email-address-frame {
  margin: 0 !important;
  position: absolute;
  top: calc(50% - 11.5px);
  left: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.input-inner2,
.input7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.input-inner2 {
  align-self: stretch;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #929292;
  box-sizing: border-box;
  position: relative;
}
.input7 {
  flex: 1;
  max-width: 100%;
}
.frame-button-dropdown1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.address1 {
  color: #909090;
}
.span5 {
  color: red;
}
.email-address3 {
  width: 64px;
  position: relative;
  font-size: 14px;
  font-family: Poppins;
  text-align: left;
  display: none;
}
.email-address-wrapper1 {
  width: 157px;
  height: 27px;
  margin: 0 !important;
  position: absolute;
  top: calc(50% - 14.5px);
  left: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.input-inner3,
.input8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.input-inner3 {
  align-self: stretch;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #929292;
  box-sizing: border-box;
  position: relative;
}
.input8 {
  flex: 1;
  max-width: 100%;
}
.frame-button-dropdown2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.select-state4 {
  color: #9e9e9e;
}
.span6 {
  color: #f30f0f;
}
.select-state3 {
  flex: 1;
  position: relative;
  font-size: 14px;
  font-family: Poppins;
  text-align: left;
}
.down-icon14 {
  height: 18px;
  width: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.dropdown3,
.select-state-parent1 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.select-state-parent1 {
  height: 50px;
  border-radius: 5px;
  border: 1px solid #929292;
  box-sizing: border-box;
  padding: 10px 21px 10px 19px;
  gap: 0 20px;
  white-space: nowrap;
}
.dropdown3 {
  min-width: 255px;
}
.select-city {
  color: #858585;
}
.span7 {
  color: red;
}
.select-state5 {
  flex: 1;
  position: relative;
  font-size: 14px;
  font-family: Poppins;
  text-align: left;
}
.down-icon15 {
  height: 18px;
  width: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.select-state-parent2 {
  height: 50px;
  flex: 1;
  border-radius: 5px;
  border: 1px solid #929292;
  box-sizing: border-box;
  padding: 10px 21px 10px 19px;
  gap: 0 20px;
  white-space: nowrap;
}
.dropdown4,
.frame-button-dropdown3,
.select-state-parent2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.dropdown4 {
  flex: 1;
  min-width: 255px;
}
.frame-button-dropdown3 {
  align-self: stretch;
  flex-wrap: wrap;
  padding: 0 1px 0 0;
  box-sizing: border-box;
  gap: 0 15px;
}
.contact-number {
  color: #909090;
}
.span8 {
  color: red;
}
.email-address4 {
  height: 21px;
  position: relative;
  font-size: 14px;
  font-family: Poppins;
  text-align: left;
  display: inline-block;
  white-space: nowrap;
}
.email-address-wrapper2 {
  margin: 0 !important;
  position: absolute;
  top: calc(50% - 11.5px);
  left: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.input-inner4,
.input9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.input-inner4 {
  align-self: stretch;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #929292;
  box-sizing: border-box;
  justify-content: flex-start;
  position: relative;
}
.input9 {
  flex: 1;
  justify-content: center;
  max-width: 100%;
}
.frame-button-dropdown4,
.input-email-address {
  margin-top: 10px;
  width: 393px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.input-email-address {
  width: 800px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px 0;
}
.button69 {
  position: relative;
  font-size: 16px;
  line-height: 130%;
  font-family: Inter;
  color: #fff;
  text-align: center;
}
.buttons-states-dark57 {
  cursor: pointer;
  border: 1px solid #fff;
  padding: 14px 11px;
  background-color: transparent;
  width: 62px;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.buttons-states-dark57:hover {
  background-color: rgba(230, 230, 230, 0.09);
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
}
.button70 {
  position: relative;
  font-size: 16px;
  line-height: 130%;
  font-family: Inter;
  color: #fff;
  text-align: center;
}
.buttons-states-dark58 {
  cursor: pointer;
  border: 0;
  padding: 14px 6px 14px 12px;
  background-color: #c21f24;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
}
.buttons-states-dark58:hover {
  background-color: #e8454a;
}
.addresses-frame,
.buttons-states4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.buttons-states4 {
  flex-direction: row;
  gap: 0 16px;
  cursor: pointer;
}
.addresses-frame {
  margin: 0;
  width: 1320px;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box;
  gap: 16px 0;
  max-width: 100%;
}
