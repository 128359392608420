.logo-icon4 {
  height: 80px;
  width: 80px;
  position: relative;
  object-fit: cover;
  cursor: pointer;
}
.f-r-a-m-e-h-e-a-d-e-r {
  width: 403px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.location-icon12 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 16px;
}
.select-location4 {
  flex: 1;
  position: relative;
  line-height: 130%;
  text-transform: uppercase;
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
}
.s-e-l-e-c-t-location {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 4px;
  max-width: 100%;
}
.set-your-location4 {
  flex: 1;
  position: relative;
  line-height: 130%;
  white-space: nowrap;
}
.buttons-states-instance,
.set-location-frame {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.buttons-states-instance {
  flex: 1;
}
.set-location-frame {
  align-self: stretch;
  gap: 0 20px;
  font-size: 16px;
  color: #fff;
}
.buttons-states6 {
  flex: 1;
  border-radius: 5px;
  background-color: #363636;
  border: 1px solid #424242;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 11px 10px 9px;
  max-width: 100%;
  cursor: pointer;
  text-align: left;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
}
.button74 {
  position: relative;
  line-height: 130%;
  text-transform: uppercase;
}
.buttons-states-dark62 {
  height: 49px;
  border-radius: 5px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 12px;
  box-sizing: border-box;
}
.john-smith9 {
  height: 21px;
  width: 95px;
  position: relative;
  line-height: 130%;
  text-transform: uppercase;
  display: none;
  white-space: nowrap;
}
.frame-u-s-e-r-b-u-t-t-o-n {
  height: 49px;
  justify-content: flex-end;
  gap: 0 5px;
}
.basket-instance,
.buttons-frame2,
.frame-u-s-e-r-b-u-t-t-o-n,
.header5 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.basket-instance {
  height: 63px;
  justify-content: flex-end;
  gap: 0 20px;
}
.buttons-frame2,
.header5 {
  flex-shrink: 0;
  max-width: 100%;
}
.buttons-frame2 {
  margin: 0;
  width: 957px;
  justify-content: flex-end;
  gap: 0 30px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  font-family: Poppins;
}
.header5 {
  align-self: stretch;
  height: 100px;
  background: radial-gradient(50% 50%at 50% 50%, #252525, #380000);
  box-shadow: 0 4px 16.8px rgba(255, 255, 255, 0.14);
  overflow: hidden;
  justify-content: center;
  padding: 32px 40px;
  box-sizing: border-box;
  top: 0;
  z-index: 99;
  position: sticky;
}
@media screen and (max-width: 1350px) {
  .set-location-frame {
    display: none;
  }
}
@media screen and (max-width: 1150px) {
  .set-your-location4 {
    display: none;
  }
}
