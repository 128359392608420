.category-10 {
  border-radius: 10px;
  background-color: #363636;
  overflow: hidden;
  /* padding: 40px; */
  box-sizing: border-box;
  gap: 30px 0;
  max-width: 100%;
  text-align: center;
  font-size: 36px;
  color: #fff;
  font-family: Poppins;
  /* margin-bottom: 20%; */
}
.shareLink{
  /* height: 10vh; */
}
.shareLink .permalink {
  position: relative;
  border-radius: 10px;
}
.shareLink .permalink .textLink {
  text-align: center;
  padding: 12px 60px 12px 30px;
  height: 45px;
  /* width: 450px; */
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #ffffff;
  border-radius: 10px;
  /* border: 1px solid #f2f2f2; */
  background-color: #575252;
  outline: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  transition: all 0.3s ease;
  margin-bottom: 100px;
}
@media (max-width: 767px) {
  .shareLink{
    height: 10vh;
  }
  .swiper-slide img{
    margin-bottom: 0px;
  }
  .shareLink .permalink .textLink {
    /* width: 100%; */
  }

  .shareLink .permalink .copyLink {

    right: 20%;
  }

}
@media (min-width: 767px) {
  .shareLink .permalink .copyLink {

    right: 38%; 
  }
}
.shareLink .permalink .textLink:focus {
  border-color: #d8d8d8;
}
.shareLink .permalink .textLink::-moz-selection {
  color: #fff;
  background-color: #ff0a4b;
}
.shareLink .permalink .textLink::selection {
  color: #fff;
  background-color: #ff0a4b;
}
.shareLink .permalink .copyLink {
  position: absolute;
  top: 21%;
  /* right: 35%; */
  cursor: pointer;
  transform: translateY(-50%);
}
.shareLink .permalink .copyLink:hover:after {
  opacity: 1;
  transform: translateY(0) translateX(-50%);
}
.shareLink .permalink .copyLink:after {
  content: attr(tooltip);
  width: 140px;
  bottom: -40px;
  left: 50%;
  padding: 5px;
  border-radius: 4px;
  font-size: 0.8rem;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  background-color: #000000;
  color: #ffffff;
  transform: translateY(-10px) translateX(-50%);
  transition: all 300ms ease;
  text-align: center;
}
.shareLink .permalink .copyLink i {
  font-size: 20px;
  color: #ff0a4b;
}
.slide-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.card {
  width: 100px;
  padding: 0;
  border-radius: 10px;
  background: #27263c;
  box-shadow: 0px -6.8px 9.8px rgba(0, 0, 0, 0.003),
    0px -10.8px 23.5px rgba(0, 0, 0, 0.008),
    0px -10.3px 44.3px rgba(0, 0, 0, 0.016),
    0px -1.5px 79.1px rgba(0, 0, 0, 0.026),
    0px 28.5px 147.9px rgba(0, 0, 0, 0.042), 0px 174px 354px rgba(0, 0, 0, 0.07);
}




input {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: transparent;
  padding: 12px;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

button {
  background: #864dfb;
  color: #f9f9f9;
  border: 0;
  cursor: pointer;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 40px 0 130px; */
}

.swiper .swiper-pagination .swiper-pagination-bullet {
  box-shadow: none;
  background: #864dfb;
  width: 16px;
  height: 16px;
}

.swiper-horizontal .swiper-pagination-bullets.swiper-pagination-horizontal {
  top: auto;
  bottom: 56px;
}

.swiper-slide img {
  /* height: 170px; */
  /* margin-bottom: 10px; */
}


/* Slider css end */
.review-section-frame {
  align-self: stretch;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 20px;
  box-sizing: border-box;
  max-width: 100%;
  flex-shrink: 0;
}
.image-4-icon1 {
  height: 840px;
  width: 1804px;
  position: absolute;
  margin: 0 !important;
  top: calc(66% - 503px);
  /* left: calc(66% - 842px); */
  object-fit: cover;
}
.mappin-icon4,
.mappin-icon5,
.mappin-icon6,
.mappin-icon7 {
  height: 24px;
  width: 24px;
  position: absolute;
  margin: 0 !important;
  top: calc(66% - 62px);
  left: calc(66% - 470px);
  z-index: 1;
}
.mappin-icon5,
.mappin-icon6,
.mappin-icon7 {
  top: calc(66% + 88px);
  left: calc(66% - 418px);
}
.mappin-icon6,
.mappin-icon7 {
  top: calc(66% - 42px);
  left: calc(66% + 120px);
}
.mappin-icon7 {
  top: calc(66% + 59px);
  left: calc(66% - 787px);
}
.location-pin-map {
  width: 110%;
  height: 400px;
  overflow: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  gap: 0 40px;
}
.homepage1,
.location1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.location1 {
  width: 1320px;
  overflow-x: auto;
  align-items: flex-start;
  padding: 0 0 40px;
  box-sizing: border-box;
  gap: 30px 0;
  max-width: 100%;
  flex-shrink: 0;
}
.homepage1 {
  width: 100%;
  position: relative;
  /* background-color: #252525; */
  overflow: hidden;
  align-items: center;
  gap: 12px 0;
  letter-spacing: normal;
}
@media screen and (max-width: 1350px) {
  .location1 {
    padding-bottom: 96px;
    box-sizing: border-box;
  }
  
}
@media screen and (max-width: 1125px) {
  .location1,
  .review-section-frame {
    padding-bottom: 44px;
    box-sizing: border-box;
  }
  .location1 {
    padding-bottom: 62px;
  }
}
@media screen and (max-width: 800px) {
  .location1 {
    gap: 30px 0;
  }
}
@media screen and (max-width: 450px) {
  .location1,
  .review-section-frame {
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .location1 {
    padding-bottom: 40px;
  }
}
.location-pin-map{
    -ms-overflow-style: none; 
    scrollbar-width: none;     
  }
.location-pin-map::-webkit-scrollbar {
    display: none;
}
/* slider css view */
.slider-view {
  width: 100%;
  height: 50vh;
  overflow: hidden;
  position: relative;
  gap: 0 70px;
  max-width: 100%;
  z-index: 1;
  text-align: left;
  font-size: 36px;
  color: #fff;
  font-family: Poppins;
}

/* LOCATION SECTION */
/* Styles for major screen sizes (e.g., desktops) */
@media (max-width: 640px) {
.description{
  font-size: var(--primary-font-size-sm-mini);
}
}